import React from 'react';
import { workbenchDataFilter$ } from '../utilities/getWorkbenchDataFilter';

export interface WorkBenchDataFilterInterface {
    dataFilter: Record<string, unknown>;
}

export const useWorkbenchDataFilter = (): WorkBenchDataFilterInterface => {
    const [dataFilter, setDataFilter] = React.useState<Record<string, unknown>>({});

    React.useEffect(() => {
        const subscriber = workbenchDataFilter$.subscribe((data): void => {
            setDataFilter(data);
        });
        return (): void => subscriber.unsubscribe();
    }, []);

    return { dataFilter };
};
