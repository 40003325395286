export const WORKBENCH_COLLAPSE_STORAGE_KEY = 'WORKBENCH_COLLAPSED';

const isWorkbenchCollapsed = (): boolean => localStorage.getItem(WORKBENCH_COLLAPSE_STORAGE_KEY) === 'true';

const setWorkbenchCollapsed = (isCollapsed: boolean): void =>
    localStorage.setItem(WORKBENCH_COLLAPSE_STORAGE_KEY, String(isCollapsed));

export const DefaultCollapseState = {
    isWorkbenchCollapsed,
    setWorkbenchCollapsed,
};

export default DefaultCollapseState;
