import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { initializeIcons } from '@fluentui/react';
import { ErrorBoundary } from '@samc/react-ui-core';
import Root from './root.component';

initializeIcons();

const domElementGetter = (): HTMLElement => {
    let el = document.getElementById('@samc/single-spa-workbench');
    if (!el) {
        el = document.createElement('div');
        el.id = '@samc/single-spa-workbench';
        document.body.appendChild(el);
    }

    return el;
};

const lifecycles = singleSpaReact({
    renderType: 'createRoot',
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundaryClass: ErrorBoundary,
    domElementGetter,
});

export const { bootstrap, mount, unmount } = lifecycles;
export { useWorkbenchStatus } from './hooks/useWorkbenchStatus';
export { workbenchStatus$, updateWorkbenchStatus } from './utilities/getWorkbenchStatus';
export { workbenchDataFilter$, updateWorkbenchDataFilter } from './utilities/getWorkbenchDataFilter';
