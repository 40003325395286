import { BehaviorSubject } from 'rxjs';

// Creating subjects to mutate data over time
const componentSubject = new BehaviorSubject<Record<string, unknown>>({});

// Defining observables from subjects to being able to subscribe to change updates.
const workbenchDataFilter$ = componentSubject.asObservable();

/**
 *
 * Updates the user config data subject by triggering the observer next() method
 * Must have been subscribed to the workbenchDataFilter$ observable to listen to changes.
 * @param config - receives a boolean assignment
 */
const updateWorkbenchDataFilter = (data: Record<string, unknown>): void => componentSubject.next(data);

export { workbenchDataFilter$, updateWorkbenchDataFilter };
