import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useHistoryContext } from '@samc/react-ui-history';
import { useWorkbenches, WorkbenchItem } from '@samc/workbench-api';
import { ServersideWorkbench } from '@samc/workbench-core';
import { updateWorkbenchStatus } from '../utilities/getWorkbenchStatus';
import { useWorkbenchDataFilter } from '../hooks/useWorkbenchDataFilter';
import Logo from '../assets/logo-vms-next-sidebar.svg';
import { DefaultCollapseState } from '../utilities/DefaultCollapseState/DefaultCollapseState';
import { DefaultLocationState } from '../utilities/workbenchLocation/workbenchLocation';

export const Workbench = (): React.ReactElement => {
    const [id, setId] = React.useState('');
    const [collapsed, setCollapsed] = React.useState(DefaultCollapseState.isWorkbenchCollapsed());
    const navigate = useNavigate();
    const { browserHistory } = useHistoryContext();
    const { data } = useWorkbenches();
    const { dataFilter } = useWorkbenchDataFilter();
    const [location, setLocation] = React.useState(browserHistory.location);

    React.useEffect(() => {
        return browserHistory.listen((l) => setLocation(l.location));
    }, [browserHistory]);

    React.useEffect(() => {
        if (data && data.length >= 1) {
            setId(data[0].id);
        }
    }, [data]);

    React.useEffect(() => {
        DefaultCollapseState.setWorkbenchCollapsed(collapsed);
    }, [collapsed]);

    const saveWorkbenchLocation = React.useCallback((workbenchItem: WorkbenchItem): void => {
        DefaultLocationState.setworkBenchLastLocation(workbenchItem);
    }, []);

    const actions = React.useMemo(
        () => ({
            navigateTo: (workbenchItem: WorkbenchItem): void => {
                if (workbenchItem.configurations) {
                    const parsedConfig = JSON.parse(workbenchItem.configurations) as {
                        relPath: string;
                    };
                    navigate(parsedConfig.relPath);
                    saveWorkbenchLocation(workbenchItem);
                }
            },
        }),
        [navigate, saveWorkbenchLocation],
    );

    // Is compared in each element from useWorkbenches
    const isWorkbenchItemSelected = React.useCallback(
        (workbenchItem: WorkbenchItem): boolean => {
            const { hash, pathname } = location;
            if (workbenchItem.configurations && pathname) {
                try {
                    const storageWorkbenchItem = DefaultLocationState.workbenchLastLocation();
                    const { relPath } = JSON.parse(workbenchItem.configurations) as { relPath: string };
                    const baseHashPath = hash.split('?')[0].replace(/#/g, '');
                    const [expectedPath, expectedHash] = relPath.split('#');
                    if (!storageWorkbenchItem) {
                        if (relPath.includes(baseHashPath)) {
                            DefaultLocationState.setworkBenchLastLocation(workbenchItem);
                        }
                    }
                    if (storageWorkbenchItem) {
                        const { configurations } = JSON.parse(storageWorkbenchItem) as { configurations: string };
                        const { relPath: storageRelPath } = JSON.parse(configurations) as { relPath: string };
                        const [storageExpectedPath] = storageRelPath.split('#');
                        // save location storage even if we did not click in a workbech option and we come from another window
                        if (pathname !== storageExpectedPath && storageRelPath === relPath) {
                            DefaultLocationState.setworkBenchLastLocation(workbenchItem);
                        }
                        if (relPath === storageRelPath) return true;
                    }
                    if (relPath.includes('#')) {
                        if (!storageWorkbenchItem && relPath.includes(baseHashPath)) {
                            console.log(workbenchItem);
                            DefaultLocationState.setworkBenchLastLocation(workbenchItem);
                        }
                        return (
                            expectedPath === pathname &&
                            (expectedHash === baseHashPath || baseHashPath.startsWith(`${expectedHash}/`))
                        );
                    }
                    return relPath === pathname;
                } catch (e) {
                    return false;
                }
            }
            return false;
        },
        [location],
    );

    const onCollapse = React.useCallback((isCollapsed: boolean) => {
        updateWorkbenchStatus(!isCollapsed);
        setCollapsed(isCollapsed);
    }, []);

    const redirectToLandingPage = (): void => {
        window.location.href = `${window.location.origin}/`;
        DefaultLocationState.deleteLastWorkbechLocation();
    };

    if (!id) return <></>;

    return (
        <ServersideWorkbench
            actions={actions}
            id={id}
            collapsed={collapsed}
            isWorkbenchItemSelected={isWorkbenchItemSelected}
            isWorkbenchItemVisible={(workbenchItem: WorkbenchItem): boolean => workbenchItem.displayText !== ''}
            onCollapseChange={onCollapse}
            headerContent={
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <img
                    onClick={(): void => {
                        redirectToLandingPage();
                    }}
                    src={Logo}
                    alt="VMSNext"
                    style={{
                        height: '28px',
                        width: '151px',
                        marginLeft: '30px',
                        marginTop: '20px',
                        cursor: 'pointer',
                    }}
                />
            }
            data={dataFilter}
        />
    );
};
export default Workbench;
