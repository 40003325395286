import { BehaviorSubject } from 'rxjs';

// Creating subjects to mutate data over time
const componentSubject = new BehaviorSubject<boolean>(true);

// Defining observables from subjects to being able to subscribe to change updates.
const workbenchStatus$ = componentSubject.asObservable();

/**
 * Specifies the open status of the workbench
 *
 * Updates the user config data subject by triggering the observer next() method
 * Must have been subscribed to the workbenchStatus$ observable to listen to changes.
 * @param config - receives a boolean assignment
 */
const updateWorkbenchStatus = (isOpen: boolean): void => componentSubject.next(isOpen);

export { workbenchStatus$, updateWorkbenchStatus };
