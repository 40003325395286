import * as React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getWorkbenches } from '../api/Requests';
import { WorkbenchContext } from '../WorkbenchContext';
export const WORKBENCH_KEY = 'workbench';
export const useWorkbenches = () => {
    const endpoint = React.useContext(WorkbenchContext);
    const query = () => getWorkbenches(endpoint.WorkbenchApi, endpoint.requestInit);
    return useRequest(WORKBENCH_KEY, query, WorkbenchContext);
};
