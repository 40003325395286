import { WorkbenchItem } from '@samc/workbench-api';

export const WORKBENCH_LOCATION_STORAGE_KEY = 'WORKBENCH_LOCATION';

const workbenchLastLocation = (): string => {
    return sessionStorage.getItem(WORKBENCH_LOCATION_STORAGE_KEY) || '';
};

const setworkBenchLastLocation = (workbenchItem: WorkbenchItem): void =>
    sessionStorage.setItem(WORKBENCH_LOCATION_STORAGE_KEY, JSON.stringify(workbenchItem));

const deleteLastWorkbechLocation = (): void => {
    sessionStorage.removeItem(WORKBENCH_LOCATION_STORAGE_KEY);
};

export const DefaultLocationState = {
    workbenchLastLocation,
    setworkBenchLastLocation,
    deleteLastWorkbechLocation,
};

export default DefaultLocationState;
