import { Spinner, SpinnerSize } from '@fluentui/react';
import { toastError } from '@samc/react-ui-core';
import { useWorkbench } from '@samc/workbench-api';
import React from 'react';
import { Workbench } from '../Workbench';
export const ServersideWorkbench = (props) => {
    const { id, headerContent, actions, collapsed, isWorkbenchItemVisible, isWorkbenchItemSelected, onCollapseChange, data, onLoaded, } = props;
    const { isError, isLoading, data: workbench } = useWorkbench(id);
    React.useEffect(() => {
        if (isError) {
            toastError('Workbench failed to load');
        }
    }, [isError]);
    React.useEffect(() => {
        if (workbench && onLoaded) {
            onLoaded(workbench);
        }
    }, [onLoaded, workbench]);
    const header = React.useMemo(() => isLoading ? (React.createElement("div", { style: {
            height: 65,
            width: 280,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        } },
        React.createElement(Spinner, { size: SpinnerSize.large }))) : (headerContent), [headerContent, isLoading]);
    return (React.createElement(Workbench, { headerContent: header, actions: actions, workbench: workbench, collapsed: collapsed, isWorkbenchItemVisible: isWorkbenchItemVisible, isWorkbenchItemSelected: isWorkbenchItemSelected, onCollapseChange: onCollapseChange, data: data }));
};
