var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mergeHeaders } from '@samc/react-ui-request';
import { GetHeaders } from './GetHeaders';
const fetchInternal = (input, init) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(input, init);
    if (response.status >= 400 && response.status < 600) {
        const body = yield response.json();
        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${response.statusText}: ${body.messages[0]}`);
        }
    }
    return response;
});
export const getWorkbenches = (url, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/workbench`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const getWorkbenchesRequest = (url, requestInit) => {
    return () => {
        return getWorkbenches(url, requestInit);
    };
};
export const getWorkbench = (url, workbenchId, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!workbenchId) {
        return Promise.reject(new Error('Invalid workbenchId'));
    }
    const response = yield fetchInternal(`${url}/api/v1/workbench/${workbenchId}`, {
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers),
    });
    return response.json();
});
export const saveWorkbench = (url, requestInit) => (workbench) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/workbench`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(workbench),
    });
    return response.json();
});
