import React from 'react';
import { Workbench as RUIWorkbench } from '@samc/react-ui-core/lib/organisms/Workbench/Workbench';
import { toastError } from '@samc/react-ui-core';
import { StyledNavItemI } from '@samc/react-ui-core/lib/atoms/NavItem/Level 1/StyledNavItemI';
import { StyledNavItemIII } from '@samc/react-ui-core/lib/atoms/NavItem/Level 3/StyledNavItemIII';
import { InnerNavSection } from '@samc/react-ui-core/lib/molecules/NavSection/InnerNavSection';
import OuterNavSection from '@samc/react-ui-core/lib/molecules/NavSection/OuterNavSection';
import { ExpressionEvaluator } from '@samc/expressions-core';
import StyledNavItemII from '@samc/react-ui-core/lib/atoms/NavItem/Level 2/StyledNavItemII';
const evaluator = new ExpressionEvaluator();
const getWorkbenchDepth = (items) => {
    if (!Array.isArray(items))
        return getWorkbenchDepth([items]);
    return items
        .map((i) => {
        if (!i.children)
            return 1;
        return 1 + getWorkbenchDepth(i.children);
    })
        .reduce((prev, cur) => Math.max(prev, cur), 0);
};
const getItemVisible = (item, data, isWorkbenchItemVisible) => {
    try {
        if (isWorkbenchItemVisible && !isWorkbenchItemVisible(item))
            return false;
        if (!item.visibleExpression ||
            (item.visibleExpression && Boolean(evaluator.evaluate(data || {}, item.visibleExpression)))) {
            const { children } = item;
            if (!children || children.length === 0)
                return true;
            return children.some((c) => getItemVisible(c, data));
        }
        return false;
    }
    catch (e) {
        return false;
    }
};
const getWorkbenchItem = (props) => {
    const { item, data, onSelect, isSelected: isSelectedProp, isWorkbenchItemVisible, workbenchDepth } = props;
    const { iconName, displayText } = item;
    const depth = getWorkbenchDepth(item);
    const isSelected = isSelectedProp !== null && isSelectedProp !== void 0 ? isSelectedProp : (() => false);
    const visible = getItemVisible(item, data, isWorkbenchItemVisible);
    if (!visible)
        return undefined;
    switch (depth) {
        case 1: {
            return (React.createElement(StyledNavItemI, { key: `${item.id}_${displayText}`, iconName: iconName, content: displayText, onClick: () => onSelect(item), selected: isSelected(item), hotkey: item.hotkey }));
        }
        case 2: {
            return (React.createElement(OuterNavSection, { key: `${item.id}_${displayText}`, headerText: displayText, hotkey: item.hotkey }, item.children
                .filter((f) => getItemVisible(f, data))
                .sort((a, b) => a.sequence - b.sequence)
                .map((c) => workbenchDepth === 2 ? (React.createElement(StyledNavItemIII, { key: c.id, content: c.displayText, selected: isSelected(c), onClick: () => onSelect(c), hotkey: c.hotkey })) : (React.createElement(StyledNavItemII, { key: c.id, content: c.displayText, selected: isSelected(c), onClick: () => onSelect(c), hotkey: c.hotkey })))));
        }
        case 3:
        default: {
            return (React.createElement(OuterNavSection, { key: `${item.id}_${displayText}`, headerText: displayText, hotkey: item.hotkey }, item.children
                .filter((f) => getItemVisible(f, data))
                .sort((a, b) => a.sequence - b.sequence)
                .map((c) => {
                const innerDepth = getWorkbenchDepth(c);
                if (innerDepth === 1) {
                    return (React.createElement(StyledNavItemII, { key: c.id, content: c.displayText, selected: isSelected(c), onClick: () => onSelect(c), hotkey: c.hotkey }));
                }
                return (React.createElement(InnerNavSection, { key: c.id, headerText: c.displayText, hotkey: c.hotkey }, c.children
                    .filter((f) => getItemVisible(f, data))
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((d) => (React.createElement(StyledNavItemIII, { key: d.id, content: d.displayText, selected: isSelected(d), onClick: () => onSelect(d), hotkey: d.hotkey })))));
            })));
        }
    }
};
export const Workbench = (props) => {
    const { actions, workbench, collapsed, isWorkbenchItemSelected, isWorkbenchItemVisible, onCollapseChange, headerContent, data, } = props;
    const workbenchDepth = React.useMemo(() => (workbench ? getWorkbenchDepth(workbench.items) : 0), [workbench]);
    React.useEffect(() => {
        if (!workbench)
            return;
        if (workbenchDepth > 3)
            toastError(`Workbench exceeds the maximum depth of 3, actual depth: ${workbenchDepth}`);
    }, [workbench, workbenchDepth]);
    const onSelect = React.useCallback((item) => {
        const action = item.actionType;
        const callback = actions[action];
        if (!callback)
            return;
        callback(item);
    }, [actions]);
    const children = React.useMemo(() => !workbench || workbenchDepth === 0 || workbenchDepth > 3
        ? []
        : workbench.items
            .sort((a, b) => a.sequence - b.sequence)
            .map((item) => {
            return getWorkbenchItem({
                item,
                data,
                onSelect,
                isSelected: isWorkbenchItemSelected,
                isWorkbenchItemVisible,
                workbenchDepth,
            });
        })
            .filter((w) => !!w), [data, isWorkbenchItemSelected, isWorkbenchItemVisible, onSelect, workbench, workbenchDepth]);
    return (React.createElement(RUIWorkbench, { collapsed: collapsed, setCollapsed: onCollapseChange, headerContent: headerContent }, children));
};
