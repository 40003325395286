import React from 'react';
import { workbenchStatus$ } from '../utilities/getWorkbenchStatus';

export interface WorkBenchStatusInterface {
    isWorkbenchOpen: boolean;
}

export const useWorkbenchStatus = (): WorkBenchStatusInterface => {
    const [isWorkbenchOpen, setIsWorkBenchOpen] = React.useState<boolean>(true);

    React.useEffect(() => {
        const subscriptor = workbenchStatus$.subscribe((isOpen) => {
            setIsWorkBenchOpen(isOpen);
        });
        return (): void => subscriptor.unsubscribe();
    }, []);

    return { isWorkbenchOpen };
};
