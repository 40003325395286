/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Router } from 'react-router-dom';
import { WorkbenchContextProvider } from '@samc/workbench-api';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { AuthorizationWrapper, useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { BrowserHistory } from 'history';
import { LayerWrapper } from '@samc/react-ui-core';
import { defaultTheme, ThemeProvider } from '@samc/react-ui-theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useClientConfiguration } from '@samc/single-spa-client-configuration';
import { Workbench } from './pages/Workbench';

interface Props {
    styletronEngine: Styletron;
    browserHistory: BrowserHistory;
}

const Root = (): React.ReactElement => {
    return <Workbench />;
};

const queryClient = new QueryClient();

const Wrapper = (props: Props): React.ReactElement => {
    const { styletronEngine, browserHistory } = props;

    const [staticRequestInit] = React.useState<RequestInit>({});
    const [location, setLocation] = React.useState(browserHistory.location);
    React.useEffect(() => {
        return browserHistory.listen((l) => setLocation(l.location));
    }, [browserHistory]);

    const authState = useUserAuthenticationState();
    const isUserAuthenticated = React.useMemo(() => !!authState?.isAuthenticated, [authState]);
    const token = authState?.accessToken?.value;

    const tenantState = useTenantState();
    const tenantName = tenantState?.name;

    const { result: clientConfiguration } = useClientConfiguration();

    const headers = React.useMemo((): Record<string, string> => {
        const h = {
            Authorization: `Bearer ${token}`,
            ...(tenantName && {
                'X-Tenant': tenantName,
            }),
        };
        Object.assign<RequestInit, RequestInit>(staticRequestInit, { headers: h });
        return h;
    }, [tenantName, token]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!isUserAuthenticated || !tenantName || clientConfiguration === undefined) return <></>;

    return (
        <AuthorizationWrapper
            config={{
                ...clientConfiguration,
                defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
            }}
            jwt={token}
        >
            <QueryClientProvider client={queryClient}>
                <ThemeProvider value={defaultTheme}>
                    <LayerWrapper>
                        <StyletronProvider value={styletronEngine} debugAfterHydration>
                            <WorkbenchContextProvider
                                value={{
                                    requestInit: staticRequestInit,
                                    WorkbenchApi: '/workbench',
                                    ShowMessage: (): void => undefined,
                                }}
                            >
                                <Router location={location} navigator={browserHistory}>
                                    <Root />
                                </Router>
                            </WorkbenchContextProvider>
                        </StyletronProvider>
                    </LayerWrapper>
                </ThemeProvider>
            </QueryClientProvider>
        </AuthorizationWrapper>
    );
};

export default Wrapper;
