import * as React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { getWorkbench } from '../api/Requests';
import { WorkbenchContext } from '../WorkbenchContext';
export const WORKBENCH_KEY = 'workbench';
export const useWorkbench = (workbenchId) => {
    const endpoint = React.useContext(WorkbenchContext);
    const query = (w) => getWorkbench(endpoint.WorkbenchApi, w, endpoint.requestInit);
    return useRequest(WORKBENCH_KEY, query, WorkbenchContext, workbenchId);
};
